import React, { forwardRef } from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Box, BoxProps } from 'app/fan/components/atoms';

export const BasePageBox = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      children,
      backgroundColor,
      backgroundImage,
      minHeight,
      display,
      animation,
      w = '100vw',
      ...props
    },
    ref
  ) => {
    const { dimensions } = useCurrentTheme();

    return (
      <Box
        w={w}
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        display={display}
        minHeight={minHeight}
        animation={animation}
        ref={ref}
      >
        <Box
          withMaxPageWidth={true}
          px={dimensions.horizontalPadding.page}
          minHeight={minHeight}
          display={display}
          w="100%"
          {...props}
        >
          {children}
        </Box>
      </Box>
    );
  }
);
