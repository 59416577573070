import React from 'react';
import styled, { css } from 'styled-components';

import { useButtonClickBehavior } from 'app/shared/utils/useButtonClickBehavior';
import {
  withPositionCSS,
  WithPositionProps,
} from 'app/shared/components/styleUtils';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { Icon, IconProps } from 'app/fan/components/atoms/Icon';

export interface IconButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    React.AriaAttributes,
    IconProps,
    WithPositionProps,
    WithSpacingProps {}

const StyledButton = styled.button<IconButtonProps>`
  ${({ disabled = false, backgroundColor }) => css`
    border: none;
    ${backgroundColor && `background-color: ${backgroundColor}`};
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: fit-content;
    width: fit-content;

    ${disabled &&
      `
      border: none;
      background-color: unset;
      cursor: auto;
      pointer-events: none;
    `}
  `}

  ${withPositionCSS};
  ${withSpacingCSS};
`;

export const IconButton: React.FC<IconButtonProps> = ({
  position,
  top,
  right,
  disabled,
  onClick,
  'data-qaid': dataQaid,
  ...props
}) => {
  const { buttonRef, handleClick } = useButtonClickBehavior({ onClick });

  const {
    p,
    pt,
    pr,
    pb,
    pl,
    m,
    mt,
    mr,
    mb,
    ml,
    backgroundColor,
    ...rest
  } = props;
  const spacingProps = { p, pt, pr, pb, pl, m, mt, mr, mb, ml };
  const stylingProps = { backgroundColor };

  return (
    <StyledButton
      ref={buttonRef}
      onClick={handleClick}
      position={position}
      top={top}
      right={right}
      disabled={disabled}
      data-qaid={dataQaid}
      {...stylingProps}
      {...spacingProps}
    >
      <Icon p={0} m={0} {...rest} data-qaid="icon-button-icon" />
    </StyledButton>
  );
};
