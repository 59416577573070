import React from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { IconButton } from 'app/shared/components/molecules/IconButton';
import { Box, Icon, Typography } from 'app/fan/components/atoms';
import { ReactComponent as AlertCircle } from 'icons/alert-circle.svg';
import { ReactComponent as AlertTriangle } from 'icons/alert-triangle.svg';
import { ReactComponent as CheckCircle } from 'icons/circle-check.svg';
import { ReactComponent as Close } from 'icons/close.svg';
import { ReactComponent as InformationCircle } from 'icons/info.svg';

export type SnackbarType = 'error' | 'info' | 'success' | 'warning';

interface SnackbarProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeSnackbar?: () => void;
  type?: SnackbarType;
  'data-qaid'?: string;
}

const SnackbarStyled = styled(Box)`
  ${({ theme }) => css`
    ${theme.media.mobile`
      max-width: 343px;
    `};

    ${theme.media.smallDesktop`
      max-width: 700px;
    `};
  `}
`;

// Make this box exactly as tall as one line of text at size fontSize75,
// and then we can use flex props to center it vertically within that line:
const StyledIconBox = styled(Box)`
  ${({ theme }) => css`
    height: calc(${theme.fontSizes.fontSize75.mobile} * 1.4);

    ${theme.media.smallDesktop`
      height: calc(${theme.fontSizes.fontSize75.desktop} * 1.4);
    `}
  `}
`;

export const Snackbar: React.FC<SnackbarProps> = ({
  children,
  type = 'error',
  isOpen,
  closeSnackbar,
  'data-qaid': qaId = 'snackbar',
}) => {
  const {
    colors: { primary: primaryColors, messaging: messagingColors },
    boxShadow,
    zIndex,
  } = useCurrentTheme();

  function getIcon() {
    switch (type) {
      case 'error':
        return AlertCircle;
      case 'info':
        return InformationCircle;
      case 'success':
        return CheckCircle;
      case 'warning':
        return AlertTriangle;
      default:
        return AlertTriangle;
    }
  }

  function getAriaRole() {
    switch (type) {
      case 'info':
      case 'success':
        return 'status';
      case 'error':
      case 'warning':
      default:
        return 'alert';
    }
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      justifyContent="center"
      w="100vw"
      data-qaid={qaId}
      zIndex={zIndex.snackbar}
    >
      <SnackbarStyled
        p={3}
        pr={4}
        role={getAriaRole()}
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={messagingColors[type].light}
        boxShadow={boxShadow.doubleShadow}
        borderRadius="4px"
      >
        <Box alignItems="flex-start" gap={2}>
          <StyledIconBox alignItems="center">
            <Icon
              data-qaid={`snackbar-icon-${type}`}
              icon={getIcon()}
              strokeColor={messagingColors[type].dark}
              fillColor="none"
              size="sm"
            />
          </StyledIconBox>
          <Typography color={messagingColors[type].dark} fontSize="fontSize75">
            {children}
          </Typography>
        </Box>
        {closeSnackbar && (
          <IconButton
            onClick={closeSnackbar}
            icon={Close}
            backgroundColor="transparent"
            fillColor="none"
            strokeColor={primaryColors.black.base}
            size="xs"
            hoverStrokeColor={messagingColors[type].dark}
            activeStrokeColor={messagingColors[type].dark}
            focusStrokeColor={messagingColors[type].dark}
            p={0}
            aria-label="Close"
          />
        )}
      </SnackbarStyled>
    </Box>
  );
};
