import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ChildMenu, ParentMenu } from 'app/typings/navbar';
import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useNavbarContext } from 'app/shared/context';
import { Logo } from 'app/shared/components/atoms';
import { Box, Divider } from 'app/shared/components/atoms';
import { IconButton } from 'app/shared/components/molecules';
import { ReactComponent as Close } from 'icons/close.svg';

import { ChildMenuItem } from './ChildMenuItem';
import { ParentMenuItem } from './ParentMenuItem';

interface Props {
  menuData: ParentMenu[];
  invertColors?: boolean;
}

const Container = styled(Box)<{ isMobileMenuOpen: boolean }>`
  ${({ isMobileMenuOpen }) => css`
    overflow-x: hidden;
    overflow-y: scroll;
    transition: transform 500ms ease;
    transform: ${isMobileMenuOpen ? 'translateX(0)' : 'translateX(100%)'};
  `}
`;

export const MobileNav: React.FC<Props> = ({ menuData, invertColors }) => {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useNavbarContext();
  const [activeParentIndex, setActiveParentIndex] = useState<number>();
  const [activeChildIndex, setActiveChildIndex] = useState<number>();
  const [activeIndicesInitialized, setActiveIndicesInitialized] = useState(
    false
  );
  const { colors, zIndex } = useCurrentTheme();
  const { isDesktop } = useIsBreakpointSize();

  const colorConfig = invertColors
    ? {
        background: colors.primary.white.base,
        primaryText: colors.primary.black.base,
        secondaryText: colors.primary.black.base,
        divider: colors.primary.white.base,
      }
    : {
        background: colors.primary.black.base,
        primaryText: colors.accent.primaryAccent.base,
        secondaryText: colors.primary.white.base,
        divider: colors.primary.black.base,
      };

  useEffect(() => {
    if (!activeIndicesInitialized && typeof window !== 'undefined') {
      menuData.forEach((parentMenuItem: any, parentIndex: any) => {
        if (
          parentMenuItem?.childMenu &&
          parentMenuItem.childMenu.length > 0 &&
          !(parentMenuItem.mobileOnly && isDesktop)
        ) {
          parentMenuItem.childMenu.forEach(
            (childMenuItem: any, childIndex: number) => {
              if (childMenuItem.link === window.location.pathname) {
                setActiveChildIndex(childIndex);
                setActiveParentIndex(parentIndex);
                setActiveIndicesInitialized(true);
              }
            }
          );
        }
      });
    }
  }, [menuData, activeIndicesInitialized, isDesktop]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  if (isDesktop) {
    return null;
  }

  return (
    menuData && (
      <Container
        position="fixed"
        top="0px"
        left="0px"
        w="100vw"
        h="100%"
        zIndex={zIndex.mobileNav}
        isMobileMenuOpen={isMobileMenuOpen}
        backgroundColor={colorConfig.background}
        px={4}
        py={8}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          pb={8}
        >
          <Logo
            variant="monogram"
            height={40}
            alt="Sofar Sounds logo"
            fillColor={colorConfig.primaryText}
          />
          <IconButton
            onClick={() => setIsMobileMenuOpen(false)}
            data-qaid="close-button"
            icon={Close}
            size="md"
            fillColor={colorConfig.background}
            strokeColor={colorConfig.primaryText}
            backgroundColor={colorConfig.background}
          />
        </Box>
        <Box overflow="hidden" pb={16} gap={6} flexDirection="column">
          {menuData.map((parentMenuItem: any, parentIndex: number) => {
            if (
              parentMenuItem &&
              parentMenuItem.childMenu.length === 0 &&
              !parentMenuItem.link
            ) {
              return null;
            }

            return (
              <React.Fragment key={parentIndex}>
                {parentIndex !== 0 && (
                  <Divider color={colorConfig.divider} my={0} />
                )}
                <Box flexDirection="column" gap={5} key={parentIndex}>
                  <ParentMenuItem
                    qaId="parent-menu-item"
                    parentMenuItem={parentMenuItem}
                    index={parentIndex}
                    key={parentMenuItem.link}
                    color={colorConfig.primaryText}
                  />
                  {parentMenuItem.childMenu.map(
                    (childMenuItem: ChildMenu, childIndex: number) => (
                      <ChildMenuItem
                        key={
                          childMenuItem.link
                            ? childMenuItem.link
                            : `${parentIndex}-${childIndex}`
                        }
                        isSelected={
                          activeParentIndex === parentIndex &&
                          activeChildIndex === childIndex
                        }
                        childData={childMenuItem}
                        parentIndex={parentIndex}
                        childIndex={childIndex}
                        textColor={colorConfig.secondaryText}
                        selectedBackgroundColor={colorConfig.primaryText}
                        selectedTextColor={colorConfig.background}
                      />
                    )
                  )}
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </Container>
    )
  );
};
