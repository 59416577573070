import React, { useContext, useRef } from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';

interface UseButtonClickEventsProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function useButtonClickBehavior({
  onClick,
}: UseButtonClickEventsProps = {}) {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const buttonRef = useRef<HTMLButtonElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const buttonNode = buttonRef.current;
    if (buttonNode) {
      trackAnalyticsEvent('Button Clicked', {
        location: buttonNode.baseURI,
        text: buttonNode.textContent,
        dataQaid: buttonNode.dataset.qaid,
        visitor_type: visitorType,
      });
    }

    if (onClick) {
      return onClick(event);
    }
  }

  return { buttonRef, handleClick };
}
